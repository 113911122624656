<template>
    <div>
        <!-- <header> -->
            <!-- <van-nav-bar title="欢迎加入鹏友会车主群"/> -->
        <!-- </header> -->
        <body >
            <van-row class="main_bg">
                <van-row type="flex" justify="end" id="logobar">
                    <van-image width="150" height="50" fit="contain" :src="imgLogo" style="margin-top:5px; margin-right:15px"/>
                </van-row>
                <div class="triangle">
                </div>
            </van-row>

            <van-row style="height: 50px">
                <p class="label-blue-bold" style="margin-left: 30px; margin-top:10px; margin-bottom: 5px">扫码添加微信</p>
                <p class="label-blue-normal" style="margin-left: 30px; margin-top:0px">以便小助理邀约你进群</p>
            </van-row>

            <van-row type="flex" align="center" style="margin-left: 30px; height: 60px">
                <p class="label-blue-larger">1</p>
                <div style="margin-left: 10px">
                    <p class="label-blue-normal" style="margin-bottom: 5px; font-weight: bold">新人礼包</p>
                    <p class="label-gray-normal" style="margin-top: 5px; margin-bottom: 20px; font-size: 11px">小鹏APP积分 + 鹏友值</p>
                </div>

                <p class="label-blue-larger" style="margin-left: 28px">2</p>
                <div style="margin-left: 10px">
                    <p class="label-blue-normal" style="margin-bottom: 5px; font-weight: bold">同城好友</p>
                    <p class="label-gray-normal" style="margin-top: 5px; margin-bottom: 20px; font-size: 11px">与同城好友交流用车疑难</p>
                </div>
            </van-row>

            <van-row type="flex" align="center" style="margin-left: 30px; height: 60px; margin-top: 20px">
                <p class="label-blue-larger">3</p>
                <div style="margin-left: 10px">
                    <p class="label-blue-normal" style="margin-bottom: 5px; font-weight: bold">专属福利</p>
                    <p class="label-gray-normal" style="margin-top: 5px; margin-bottom: 20px; font-size: 11px">有奖互动，节假日车主礼</p>
                </div>

                <p class="label-blue-larger" style="margin-left: 10px">4</p>
                <div style="margin-left: 10px">
                    <p class="label-blue-normal" style="margin-bottom: 5px; font-weight: bold">车友活动</p>
                    <p class="label-gray-normal" style="margin-top: 5px; margin-bottom: 20px; font-size: 11px">新老车主鹏友线下聚会</p>
                </div>
            </van-row>

            <van-row type="flex" justify="end" style="height: 60px; margin-top: 80px; margin-right: 30px;">
                <div style="display: flex; flex-direction: column; justify-content: flex-end;">
                    <p class="label-gray-normal" style="text-align: end; font-size: 16px; margin-right: 10px; margin-top: 0px; margin-bottom: 0px">保存二维码，微信添加好友</p>
                    <p class="label-gray-normal" style="text-align: end; margin-right: 10px; font-size: 16px; margin-top: 5px; margin-bottom: 5px">以便小助理邀约你进群</p>
                </div>
                <van-image width="100" height="100" fit="contain" :src="imgQRCode" style=""/>
            </van-row>

            <img id="logo5" style="object-fit: contain;" :src="imgLogo5"/>
            <!-- <van-image width="300" height="65" fit="scale-down" :src="imgLogo3" style="margin-top: 100px; margin-left: 30px"/>
            <span class="font-black-bold">
                <p style="margin-left: 65px; margin-bottom: 0px">欢 迎 扫 码 添 加 鹏 友 会 小 助 理</p>
                <p style="margin-left: 65px; margin-top: 5px">加 入 鹏 友 会 大 家 庭</p>
            </span>
            <div class="triangle">

            </div>
            <van-row style="margin-left:65px; margin-right:30px; margin-top: 5px">
                <span class="label-blue-bold">智驾学院</span>
                <span class="label-gray-normal">为您解答用车疑惑</span>
            </van-row>
            <van-row style="margin-left:65px; margin-right:30px; margin-top: 5px">
                <span class="label-blue-bold">专属福利</span>
                <span class="label-gray-normal">新人福利包，每月10号鹏友会</span>
            </van-row>
            <van-row style="margin-left:65px; margin-right:30px; margin-top: 5px">
                <span class="label-blue-bold">鹏友迎新</span>
                <span class="label-gray-normal">新老车主面基大会，仪式感满满</span>
            </van-row>
            <div>
                <van-image width="100" height="100" fit="contain" :src="imgQRCode" style="margin-left: 60px; margin-top: 30px"/>
                <p style="margin-left:65px; color: rgb(103, 103, 103); font-size: 14px">鹏友，遇见是最美好的意义</p>
            </div>
            <van-row type="flex" justify="center">
                <span class="label-blue-bold" style="margin-top: 25px;">长按二维码保存到相册</span>
            </van-row> -->
        </body>
    </div>
</template>

<script>
export default {
    mounted() {
        this.getScreenWH();
    },
    data() {
        return {
            imgLogo: require('../assets/images/logo.png'),
            imgLogo2: require('../assets/images/logo2.png'),
            imgLogo3: require('../assets/images/logo3.png'),
            imgLogo5: require('../assets/images/logo5.png'),
            imgQRCode: require('../assets/images/qrcode_' + this.$route.params.userId + '.png'),
            imgWidth: 10,
            imgHeight: 10
        }
    },
    methods: {
        getScreenWH () {
            this.imgWidth = screen.width;
            this.imgHeight = screen.height;
        }
    }
}
</script>

<style scoped>
body{
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
}
.main_bg{
    width: 100%;
    height: 300px;
    background-image: url('../assets/images/select_bg.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
}
#logo2{
    border-left: 0.5px solid rgb(103, 103, 103);
    border-left-width: 1px;
    padding-left: 10px;
}
.font-black-bold{
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.triangle{
    margin-top: 185px;
	width: 0px;
	height: 0px;
	border-color:   white transparent;
	border-width: 0px 230px 70px 00px;
	border-style: solid;
}
.label-gray-bold{
    font-size: 14px;
    font-weight: bold;
    color: rgb(103, 103, 103);
}
.label-gray-normal{
    font-size: 14px;
    color: rgb(103, 103, 103);
    /* border-left: 2px solid rgb(103, 103, 103); */
    /* margin-left: 7px; */
    /* padding-left: 7px; */
}
.label-blue-bold{
    font-size: 24px;
    font-weight: bold;
    color: rgb(0, 90, 218);
}
.label-blue-normal{
    font-size: 16px;
    color: rgb(0, 90, 218);
}
.label-blue-larger{
    font-size: 40px;
    font-weight: bold;
    color: rgb(0, 90, 218);
}
#logo5{
  position: absolute;
  bottom: 0%;
  z-index: -1;
  left: 0%;
  width: 350px;
  height: 350px;
}
</style>